import { FC } from 'react';
import IconProps from './IconProps';

const MediumIcon: FC<IconProps> = props => {
  return (
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2812 6.00001C11.2812 9.10626 8.75625 11.625 5.64 11.625C4.90033 11.626 4.16771 11.4813 3.48396 11.1991C2.80022 10.917 2.17875 10.5029 1.65502 9.98059C1.1313 9.45826 0.715587 8.83789 0.431617 8.1549C0.147648 7.47191 0.000984258 6.73968 0 6.00001C0 2.89251 2.525 0.375007 5.64 0.375007C6.37978 0.373857 7.11253 0.518449 7.79642 0.800525C8.48031 1.0826 9.10193 1.49663 9.62579 2.01898C10.1496 2.54133 10.5655 3.16175 10.8495 3.84482C11.1336 4.52789 11.2803 5.26023 11.2812 6.00001ZM17.4688 6.00001C17.4688 8.92501 16.2062 11.295 14.6488 11.295C13.0912 11.295 11.8288 8.92376 11.8288 6.00001C11.8288 3.07501 13.0912 0.705007 14.6488 0.705007C16.2062 0.705007 17.4688 3.07626 17.4688 6.00001ZM20 6.00001C20 8.62001 19.5562 10.7438 19.0075 10.7438C18.46 10.7438 18.0163 8.61876 18.0163 6.00001C18.0163 3.38001 18.46 1.25626 19.0087 1.25626C19.5562 1.25626 20 3.38001 20 6.00001Z"
        fill="black"
      />
    </svg>
  );
};
export default MediumIcon;
