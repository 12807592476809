import Words from './words';

const it: { [word in Words]: string } = {
  max: 'max',
  min: 'min',
  dashboard: 'Pannello',
  analytics: 'Analisi',
  community: 'Comunità',
  settings: 'Impostazioni',
  connectWallet: 'Connetti Portafogli',
  pending: 'In corso...',
  installMetamask: 'Installa Metamask',
  availablePools: 'Pool Disponibili',
  filter: 'Filtro',
  asset: 'Attività',
  assetName: 'Nome Attività',
  protocol: 'Protocollo',
  protocolName: 'Nome Protocollo',
  clearFilter: 'Cancella',
  apply: 'Applica',
  token: 'token',
  fixedApr: 'APR Fisso',
  lifeTimeApr: 'APR a Termine',
  apr: 'APR',
  aprRange: 'Gamma APR',
  lpApr: 'LP APR',
  futureApr: 'APR Futuro',
  fiat: 'Fiat',
  crypto: 'Crypto',
  pool: 'Pool',
  ofPool: 'della Pool',
  poolRatio: 'Frazionamento Pool (Principals / Yields)',
  redemption: 'Rimborso',
  earlyRedemption: 'Rimborso Anticipato',
  swap: 'Scambio',
  tvl: 'TVL',
  manage: 'Gestisci',
  basic: 'Normale',
  advanced: 'Avanzato',
  deposit: 'Deposito',
  withdraw: 'Ritiro',
  mint: 'Forgia',
  removeLiquidity: 'Rimuovi Liquidità',
  provideLiquidity: 'Immetti Liquidità',
  earlyRedeem: 'Riscatto Anticipato',
  availableToDeposit: 'Disponibilità Deposito',
  marketImpliedYield: 'APR Futuro',
  volume: 'Volume',
  fees: 'Commissioni',
  term: 'Periodo',
  startDate: 'Data di Inizio',
  maturity: 'Scadenza',
  timeLeft: 'Tempo Rimasto',
  currentPosition: 'Posizione Attuale',
  principals: 'Principals',
  yields: 'Yields',
  lpTokens: 'LP Tokens',
  staked: 'Accumulati',
  approve: 'Approva',
  approved: 'Approvato',
  approving: 'In Approvazione',
  execute: 'Esegui',
  executing: 'In Esecuzione',
  profitLoss: 'Profitti & Perdite',
  liquidationValue: 'Valore di Liquidazione',
  from: 'Da',
  to: 'A',
  balance: 'Saldo',
  futureYield: 'Rendimento Futuro',
  lifeTimeYield: 'Rendimento a Termine',
  fixYourFutureYield: 'Fissa il Rendimento Futuro',
  fixedYield: 'Rendimento Fisso',
  fixedYieldAtMaturity: 'Rendimento Fisso a Scadenza',
  totalAvailableAtMaturity: 'Totale Disponibile a Scadenza',
  variableYield: 'Rendimento Variabile',
  amountReceived: 'Importo Ricevuto',
  approx: 'Appross.',
  estimatedAmountReceived: 'Importo Ricevuto Stimato',
  estimated: 'Stimato',
  feesTooltipInfo:
    'Le commissioni di Deposito, Rimborso e Rimborso Anticipato sono versate nel Tesoro di Tempus Treasury (Tempus Treasury). La commissione di Scambio è versata ai fornitori di liquidità.',
  selectPlaceholder: 'Seleziona',
  selectTokenFirst: 'Prima Seleziona il Token',
  tempusAnnouncements: 'Tempus Annunci',
  tempusChat: 'Tempus Chat',
  interestRateProtectionTooltipText:
    "Fissa il rendimento futuro con Tempus. Questa funzione blocca il tuo Yield Bearing Token, forgia i Principals e gli Yields in cambio e scambia tutti gli Yields per Principals attraverso il TempusAMM.<br/><br/>Riceverai Principals i quali sono riscattabili 1:1 con l'attività sottostante a scadenza.",
  liquidityProvisionTooltipText:
    'Fornisci liquidità a Tempus per ottenere un rendimento aggiuntivo. Questa funzione blocca il tuo  Yield Bearing Token, forgia i Principals e gli Yields in cambio e usa il maggior numero di Principals e Yields per immettere liquidità al TempusAMM.<br/><br/>Questo significa che riceverai il rendimento sottostante e le commissioni di scambio Tempus aggregate in un unico rendimento.',
};
export default it;
