import { FC } from 'react';
import DashboardManager from '../dashboard/dashboard-manager';

import './Main.scss';

const Main: FC<{poolData: any}> = ({poolData}) => {
  return (
    <div className="tc__main">
      <DashboardManager poolData={poolData} />
    </div>
  );
};

export default Main;
