import { FC } from 'react';
import IconProps from './IconProps';

const AlertIcon: FC<IconProps> = props => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8849 0.4905C10.5389 -0.1635 9.46294 -0.1635 9.11694 0.4905L0.116935 17.4905C0.0359187 17.6429 -0.00424573 17.8136 0.00035519 17.9861C0.00495611 18.1586 0.0541655 18.327 0.143189 18.4749C0.232212 18.6227 0.358012 18.7449 0.508333 18.8297C0.658653 18.9145 0.828366 18.9588 1.00094 18.9585H19.0009C19.1734 18.9589 19.343 18.9145 19.4932 18.8298C19.6434 18.7451 19.7691 18.6229 19.8581 18.4752C19.947 18.3274 19.9961 18.1591 20.0007 17.9867C20.0052 17.8144 19.965 17.6437 19.8839 17.4915L10.8849 0.4905ZM11.0009 15.9585H9.00094V13.9585H11.0009V15.9585ZM9.00094 11.9585V6.9585H11.0009L11.0019 11.9585H9.00094Z"
        fill={props.fillColor || '#222222'}
      />
    </svg>
  );
};
export default AlertIcon;
