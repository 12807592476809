const GreenArrowUp = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#CEF2DA" />
      <path
        d="M10.4243 3.57574C10.1899 3.34142 9.81005 3.34142 9.57574 3.57574L5.75736 7.39411C5.52304 7.62843 5.52304 8.00833 5.75736 8.24264C5.99167 8.47696 6.37157 8.47696 6.60589 8.24264L10 4.84853L13.3941 8.24264C13.6284 8.47696 14.0083 8.47696 14.2426 8.24264C14.477 8.00833 14.477 7.62843 14.2426 7.39411L10.4243 3.57574ZM10.6 16L10.6 4L9.4 4L9.4 16L10.6 16Z"
        fill="#4BB543"
      />
    </svg>
  );
};
export default GreenArrowUp;
