import { FC } from 'react';
import IconProps from './IconProps';

const ArrowDown: FC<IconProps> = props => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="15.4989" cy="15" rx="14.975" ry="15" fill="white" />
      <path
        d="M15.4989 0C7.22645 0 0.523926 6.71371 0.523926 15C0.523926 23.2863 7.22645 30 15.4989 30C23.7714 30 30.4739 23.2863 30.4739 15C30.4739 6.71371 23.7714 0 15.4989 0ZM24.1941 16.6935C24.1941 17.0927 23.868 17.4194 23.4695 17.4194H17.9142V22.9839C17.9142 23.3831 17.5882 23.7097 17.1897 23.7097H13.8082C13.4097 23.7097 13.0836 23.3831 13.0836 22.9839V17.4194H7.52836C7.12983 17.4194 6.80376 17.0927 6.80376 16.6935V13.3065C6.80376 12.9073 7.12983 12.5806 7.52836 12.5806H13.0836V7.01613C13.0836 6.61694 13.4097 6.29032 13.8082 6.29032H17.1897C17.5882 6.29032 17.9142 6.61694 17.9142 7.01613V12.5806H23.4695C23.868 12.5806 24.1941 12.9073 24.1941 13.3065V16.6935Z"
        fill={props.fillColor || '#2E6398'}
      />
    </svg>
  );
};
export default ArrowDown;
